/*
  Forgot password screen. User comes here when they forgot their password. 
  They get an email with a link to an API call. The API call does a redirect to this page,
  adding authcode and email parameters

  Parameters must exist or page should not allow display

  Nice to have - make API call to get password characteristics (eg how log, etc)
               - cache the call

  Default - Password must be at least 8 chars

  Once a valid password is entered, the form can call the reset password API
*/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RestService } from '../rest.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})

export class ForgotPasswordComponent implements OnInit {
  // the forgot passrowd API call redirects to this page, with query parameters of email address (username)
  // and authentication code
  authcode: string;  
  email: string;
  isValidParameters: boolean = false;
  passwordChanged: boolean = false;
  errorDetails: any = null;

  form = new FormGroup({  
    password: new FormControl('', [Validators.required])  
  });

  constructor(private route: ActivatedRoute,
    private restAPI: RestService) { }

  ngOnInit() {
    this.route.queryParams
    //.filter(params => params.order)
    .subscribe(params => {
      console.log(params); // {order: "popular"}

      // form should not display unless both mandatory parameters are present
      this.isValidParameters = true;
      if( params.hasOwnProperty('authcode')) {
        this.authcode = params.authcode;
      } else {
        this.isValidParameters = false;
      }

      if( params.hasOwnProperty('email')) {
        this.email = params.email;
      }else {
        this.isValidParameters = false;
      }

      console.log(this.authcode);    
      console.log(this.email);  
    });  
  }

  isValidPassword() {
    var password = this.form.get('password').value;
    var passwordLength = 8;
    if( password.length < passwordLength) {
      this.form.setErrors({
        passwordShort: true
      })
      return false;
    }
    return true;
  }

  flagPasswordChanged(res: any) {
    this.passwordChanged = true;
    console.log(res);
  }

  flagPasswordChangeFailed(error: any) {
    this.passwordChanged = false;
    this.errorDetails = {
      message: error
    }
    console.log(error);
  }

  submit() {
    this.passwordChanged = false;  
    this.errorDetails = null;

    if( !this.isValidPassword()) {
      return;
    }

    var param = {
        username: this.email,
        authcode: this.authcode,
        password: this.form.get('password').value
    };      
    
    var result = this.restAPI.passwordReset(param).subscribe( 
      //data => {} );

    //.subscribe(
      (res: any) => this.flagPasswordChanged(res), //(1)
      (error: any) => this.flagPasswordChangeFailed(error), //(2)
      () => console.log('Changed password') //(3)
    );

    //this.passwordChanged = true;
    //this.form.setErrors({
    //  resetFailed: true
    //})
  }
}
