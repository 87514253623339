import { Component } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'er-helpdesk';
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
  }

}