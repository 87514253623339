import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponentComponent } from './home-component/home-component.component'; 
import { TestComponent } from './test/test.component'; 
import { EmailVerifiedComponent } from './email-verified/email-verified.component'; 
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'; 
import { NotFoundComponent } from './not-found/not-found.component'; 
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: HomeComponentComponent },
  { path: 'test', component: TestComponent },
  { path: 'email/verified', component: EmailVerifiedComponent },  
  { path: 'user/reset', component: ForgotPasswordComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: '**', component: NotFoundComponent }     
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
