import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, retry, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RestService {
  endpoint = 'https://vet.equineregister.net/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept-Language': 'en'
    })
  };


  constructor(private http: HttpClient) { }

  passwordReset(param): Observable<any> {
    // param = {
    //    username: 'alex@equineregister.co.uk',
     //   authcode: '12345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678',
    //    password: 'verysecret'
    //};

    return this.http.put(this.endpoint + 'passwordreset', 
                        JSON.stringify(param),
                        this.httpOptions)
                        .pipe(
                          retry(1),
                          catchError(this.handleError)
    );
  }


  // Error handling 
  handleError(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.detail}`;
     }
     //window.alert(errorMessage);
     return throwError(errorMessage);
  }  

}
